import request from '@/utils/request'


// 查询用户消息列表
export function listMessage(query) {
  return request({
    url: '/user/user-message/list',
    method: 'get',
    params: query
  })
}

// 查询用户消息分页
export function pageMessage(query) {
  return request({
    url: '/user/user-message/page',
    method: 'get',
    params: query
  })
}

// 查询用户消息详细
export function getMessage(data) {
  return request({
    url: '/user/user-message/detail',
    method: 'get',
    params: data
  })
}

// 新增用户消息
export function addMessage(data) {
  return request({
    url: '/user/user-message/add',
    method: 'post',
    data: data
  })
}

// 修改用户消息
export function updateMessage(data) {
  return request({
    url: '/user/user-message/edit',
    method: 'post',
    data: data
  })
}

// 删除用户消息
export function delMessage(data) {
  return request({
    url: '/user/user-message/delete',
    method: 'post',
    data: data
  })
}
