<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="消息类型: 1：平台公告  2:系统消息 3、动态点赞 4评论点赞" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="图片路径" prop="imgUrl" >
        <a-input v-model="form.imgUrl" placeholder="请输入图片路径" />
      </a-form-model-item>
      <a-form-model-item label="0-未读消息 1-已读消息" prop="isRead" >
        <a-input v-model="form.isRead" placeholder="请输入0-未读消息 1-已读消息" />
      </a-form-model-item>
      <a-form-model-item label="资源id(zb_user |zb_user_dynamic|zb_user_dynamic_comment)" prop="resourceId" >
        <a-input v-model="form.resourceId" placeholder="请输入资源id(zb_user |zb_user_dynamic|zb_user_dynamic_comment)" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMessage, addMessage, updateMessage } from '@/api/user/message'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        title: null,

        content: null,

        imgUrl: null,

        isRead: null,

        resourceId: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        type: null,
        title: null,
        content: null,
        imgUrl: null,
        isRead: null,
        resourceId: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMessage({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMessage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMessage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
